export default class SetRoute {
  constructor() {
    this.apiKey = "NrnBRT_0AD0vRKR9gs-UVQxxc7SImXnbkzwD7xxX8YY";
    this.mapContainer = null;
    this.setRouteBtns = null;
    this.lat = 0;
    this.lng = 0;
    this.currentLat = 0;
    this.currentLng = 0;
    this.wayPointBegin = "";
    this.wayPointEnd = "";
    this.init();
  }

  init() {
    this.mapContainer = document.getElementById("display-shop-location");

    const cookieLat = this.getCoordsFromCookies("tempLat");
    const cookieLng = this.getCoordsFromCookies("tempLng");
    if (cookieLat !== "" && cookieLng !== "") {
      this.currentLat = cookieLat;
      this.currentLng = cookieLng;
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        this.getCurrentCoords(position);
      });
    }

    this.setRouteBtns = document.querySelectorAll(".set-route");
    if (this.setRouteBtns != null) {
      this.setRouteBtns.forEach(button => {
        button.addEventListener("click", e => {
          e.preventDefault();
          this.lat = e.target.dataset.lat;
          this.lng = e.target.dataset.lng;

          if (
            document.body.classList.contains("page-template-find-your-shop")
          ) {
            document.querySelector(".display-route-map").style.display =
              "block";
          }

          this.setRoute();
        });
      });
    }
  }

  getCoordsFromCookies(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getCurrentCoords(position) {
    console.log("User's current coordinates:", position.coords.latitude, position.coords.longitude);
    this.currentLat = position.coords.latitude;
    this.currentLng = position.coords.longitude;
  }
  

  setRoute() {
    this.mapContainer.innerHTML = "";
    this.wayPointBegin = "geo!" + this.currentLat + "," + this.currentLng;
    this.wayPointEnd = "geo!" + this.lat + "," + this.lng;

    // Instantiate a map and platform object:
    const platform = new H.service.Platform({
      apikey: this.apiKey
    });

    // Retrieve the target element for the map:
    const targetElement = document.getElementById("display-shop-location");

    // Get the default map types from the platform object:
    const defaultLayers = platform.createDefaultLayers();

    // Instantiate the map:
    const coords = { lat: this.lat, lng: this.lng };
    const map = new H.Map(this.mapContainer, defaultLayers.vector.normal.map, {
      zoom: 14,
      center: coords
    });

    // Enable the event system on the map instance:
    const mapEvents = new H.mapevents.MapEvents(map);

    // Add event listeners:
    map.addEventListener("tap", function(evt) {
      // Log 'tap' and 'mouse' events:
      console.log(evt.type, evt.currentPointer.type);
    });

    // Instantiate the default behavior, providing the mapEvents object:
    const behavior = new H.mapevents.Behavior(mapEvents);

    // Zmieniamy zapytanie API na wersję v8
    const apiUrl = `https://router.hereapi.com/v8/routes?` +
      `transportMode=car&` +
      `waypoint0=geo!${this.currentLat},${this.currentLng}&` +
      `waypoint1=geo!${this.lat},${this.lng}&` +
      `representation=display&` +
      `apiKey=${this.apiKey}`;

    // Zmieniamy sposób wywołania zapytania, używając fetch dla wersji v8
    fetch(apiUrl)
      .then(response => response.json())
      .then(result => this.handleRouteResponse(result))
      .catch(error => {
        console.error("Error calculating route:", error);
        alert("An error occurred while calculating the route.");
      });
  }

  // Nowa metoda do obsługi odpowiedzi na trasę z API
  handleRouteResponse(result) {
    var route, routeShape, startPoint, endPoint, linestring;
    if (result.routes && result.routes.length > 0) {
      // Pick the first route from the response:
      route = result.routes[0];
      // Pick the route's shape:
      routeShape = route.sections[0].shape;

      // Create a linestring to use as a point source for the route line
      linestring = new H.geo.LineString();

      // Push all the points in the shape into the linestring:
      routeShape.forEach(function(point) {
        const parts = point.split(",");
        linestring.pushLatLngAlt(parts[0], parts[1]);
      });

      // Retrieve the mapped positions of the requested waypoints:
      startPoint = route.sections[0].departure.place.location;
      endPoint = route.sections[0].arrival.place.location;

      // Create the default UI:
      const ui = H.ui.UI.createDefault(map, defaultLayers);

      // Create a polyline to display the route:
      const routeLine = new H.map.Polyline(linestring, {
        style: { strokeColor: "blue", lineWidth: 3 }
      });

      // Create a marker for the start point:
      const startMarker = new H.map.Marker({
        lat: startPoint.lat,
        lng: startPoint.lng
      });

      // Create a marker for the end point:
      const endMarker = new H.map.Marker({
        lat: endPoint.lat,
        lng: endPoint.lng
      });

      // Add the route polyline and the two markers to the map:
      this.map.addObjects([routeLine, startMarker, endMarker]);

      // Set the map's viewport to make the whole route visible:
      this.map
        .getViewModel()
        .setLookAtData({ bounds: routeLine.getBoundingBox() });
    }
  }
}
