import SelectShop from "./selectShop";
import ShowLeaflet from "./showLeaflet";
import SetRoute from "./maps/setRoute";

const API_KEY = "8VRPF0UXGpSQt6D9oyUzeP8AIzP2s2gmU1zBlrhLyv8";

export default class FindShopService {
  constructor() {
    this.radiusSelector = document.querySelectorAll(".radius-selector");
    this.searchInput = document.querySelector(".search-shop-form input");
    this.searchform = document.querySelector(".search-shop-form");
    this.listContainer = null;
    this.maxResults = 10;
    this.shopsCoords = [];
    this.currentRadius = 5;
    this.init();
  }

  init() {
    if (this.radiusSelector != null) {
      this.handleRadiusSelection();
    }

    this.listContainer = document.createElement("div");
    this.listContainer.classList.add("suggestions-list");
    if (this.searchInput != null) {
      this.searchInput.addEventListener("click", e => {
        this.listContainer.style.display = "block";
      });

      this.searchInput.addEventListener("keyup", e => {
        this.listContainer.innerHTML = "";
        this.handleInput(e);
      });
    }

    this.getShopsCoords();
  }

  getShopsCoords() {
    const shopsList = document.querySelectorAll(".hidden-info p");
    let i = 0;
    shopsList.forEach(shop => {
      this.shopsCoords[i] = { lat: shop.dataset.lat, lng: shop.dataset.lng };
      i++;
    });
  }

  async getSuggestions(value) {
    let response = await fetch(this.suggestionsURL(value));
    let data = await response.json();
  
    if (!data.items || data.items.length === 0) {
      console.error("Brak sugestii w odpowiedzi API:", data);
      return []; // Zwracamy pustą tablicę, by uniknąć błędu
    }
  
    return this.handleSuggestions(data.items);
  }
  

  async getCoordinates(value) {
    try {
      let response = await fetch(this.coordinatesURL(value));
      let data = await response.json();
      console.log("Odpowiedź API:", data); 
  
      if (!data.items || data.items.length === 0) {
          console.warn("Brak wyników geokodowania dla:", value, data);
          alert(`Nie znaleziono współrzędnych dla: ${value}`);
          return null; // Ensure we handle the case where no coordinates are returned
      }
  
      let location = data.items[0].position;
      console.log("Współrzędne:", location); // Check if the coordinates are correct
      return { Latitude: location.lat, Longitude: location.lng }; // Return coordinates
    } catch (error) {
      console.error("Błąd podczas pobierania danych:", error);
    }
  }
  

  suggestionsURL(value) {
    return `https://autocomplete.search.hereapi.com/v1/autocomplete
        ?apiKey=${API_KEY}
        &q=${encodeURIComponent(value)}
        &in=countryCode%3APOL
        &limit=${this.maxResults}
        &countryCode=POL`.replace(/\s+/g, '');
  }

  coordinatesURL(locationId) {
    if (!locationId) {
      console.error("Brak locationId w zapytaniu geokodującym");
      return null;
    }
  
    return `https://geocode.search.hereapi.com/v1/geocode
      ?apiKey=${API_KEY}
      &q=${encodeURIComponent(locationId)}`.replace(/\s+/g, ''); // Parametr locationId jako zapytanie 'q' lub locationId
  }

  async handleInput(e) {
    const inputValue = e.target.value;
    if (inputValue.length > 2) {
      const suggList = await this.getSuggestions(inputValue);
      this.displaySuggestions(suggList);
    }
  }

  handleSuggestions(suggestions) {
    if (!suggestions || !Array.isArray(suggestions)) {
      console.error("Niepoprawne dane suggestions:", suggestions);
      return [];
    }
  
    let suggList = [];
    let uniqueLabels = [];
  
    suggestions.forEach(sugg => {
      let { title, id } = sugg; // Zaktualizujmy, aby pobierać 'title' i 'id'
  
      if (title && !uniqueLabels.includes(title)) {
        suggList.push({ locationId: id, label: title }); // Zmieniamy na 'id' z 'sugg.id'
        uniqueLabels.push(title);
      }
    });
  
    return suggList;
  }

  displaySuggestions(suggestions) {
    suggestions.forEach(suggestion => {
      let suggestionContainer = document.createElement("p");
      suggestionContainer.innerHTML = `<a href="" class="select-location" data-locationid="${suggestion.locationId}">${suggestion.label}</a>`;
      this.listContainer.appendChild(suggestionContainer);
    });
    document.querySelector(".search-shop-form").appendChild(this.listContainer);
    this.handleLocationSelection();
  }


  handleLocationSelection() {
    const suggestedLocations = document.querySelectorAll(".select-location");
    suggestedLocations.forEach(location => {
      location.addEventListener("click", async e => {
        e.preventDefault();
  
        // Użyj 'title' z sugerowanej lokalizacji zamiast 'locationid'
        const coordinates = await this.getCoordinates(location.textContent); 
        
        if (coordinates) {
          this.searchShops(coordinates);
        } else {
          console.warn("Could not find coordinates for location");
        }
  
        this.listContainer.style.display = "none";
      });
    });
  }

  searchShops(coordinates) {
    // Check if coordinates are null
    if (!coordinates) {
      console.error("Nie znaleziono współrzędnych dla tej lokalizacji.");
      return;  // Return early if no coordinates
    }

    let data = new FormData(this.searchform);
    data.append("latitude", coordinates.Latitude);
    data.append("longitude", coordinates.Longitude);
    data.append("radius", this.currentRadius);
    data.append("action", "getShops");

    return fetch(myAjax.ajaxurl, {
      method: "POST",
      body: data
    })
    .then(response => response.json())
    .catch(error => console.error("Error:", error))
    .then(response => this.handleResponse(response));
  }

  handleResponse(response) {
    const resultsContainer = document.querySelector(".display-found-shops");
    if (response.foundShops == 0) {
      resultsContainer.innerHTML =
        "<div style='grid-column: 1 / span 4; text-align: center'><h2>Przykro nam. Na tym obszarze nie znaleziono sklepów.</h2></div>";
    } else {
      resultsContainer.innerHTML = response.results;
      setTimeout(() => {
        new SelectShop();
        new ShowLeaflet();
        new SetRoute();
      }, 500);
    }
  }

  handleRadiusSelection() {
    this.radiusSelector.forEach(button => {
      button.addEventListener("click", e => {
        e.preventDefault();
        this.currentRadius = e.target.dataset.radius;
        this.removeSelectedClass();
        e.target.classList.add("selected");
      });
    });
  }

  removeSelectedClass() {
    this.radiusSelector.forEach(button => {
      button.classList.remove("selected");
    });
  }
}
